<template>
  <main class="container my-3 text-justify">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mx-auto">
        <div class="col-12 mb-4">
          <h2>» Shopsuche</h2>
          <p class="ps-4">Na, öfter hier?</p>
        </div>
        <div class="row">
          <div class="col-8">
            <p class="raleway-bold ps-4">
              Hier sind alle Shops verzeichnet, in denen du uns finden kannst.
            </p>
          </div>
          <div class="col-4 position-relative">
            <img
              class="img-fluid bottle position-absolute start-0 bottom-0"
              src="@/assets/shop-bottle.svg"
              alt="shop-bottle"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="embed-responsive embed-responsive-1by1">
            <iframe
              src="https://www.google.com/maps/d/embed?mid=1TrUNvN2cA9RM5xFeCJdIgFLRbnSo72kX"
              allowfullscreen=""
              loading="lazy"
              class="embed-responsive-item maps round"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.maps {
  width: 100%;
  height: 30rem;
}

.bottle {
  height: 5rem;
}
</style>
